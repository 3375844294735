<template>
  <div class="text-center text-primary my-2 load-content-card">
    <b-spinner style="width: 3rem; height: 3rem" class="align-middle mr-2"></b-spinner>
    <strong> {{ $t('loading') }}</strong>
  </div>
</template>

<script>

import alert from "@/common/alert";

export default {
  name: "LoadingAPI",
  mounted() {
    this.setTime()
  },
  methods: {
    setTime() {
      this.timeCountdown = setInterval(() => {
        alert.updateSuccess(this)
        clearInterval(this.timeCountdown)
        this.$router.push('/Developers').catch(() => {
        })
      }, 600);
    },
  }
}

</script>

<style scoped>
.load-content-card {
  display: block;
  max-width: 1300px;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>