import i18n from '../i18n/i18n.js'
import router from "@/router/Router";

export default {
    addNewSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.addNewSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    addNewFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.addNewFailed'),
            html: '<p>' + vm.$t('common.somethingWrong') + '</p>' + message,
            confirmButtonColor: '#3085d6',
        })
    },
    cancelSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.cancelSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    cancelFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.cancelFailed'),
            html: '<p>' + vm.$t('common.somethingWrong') + '</p>' + message,
            confirmButtonColor: '#3085d6',
        })
    },
    refundSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.refundSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    refundFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.refundFailed'),
            html: '<p>' + vm.$t('common.somethingWrong') + '</p>' + message,
            confirmButtonColor: '#3085d6',
        })
    },
    deleteSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.deleteSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    deleteFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.deleteFailed'),
            text: vm.$t('common.somethingWrong'),
            confirmButtonColor: '#3085d6',
        })
    },
    resetSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.resetSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    resetFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.resetFailed'),
            text: message,
            confirmButtonColor: '#3085d6',
        })
    },
    resendSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.resendSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    resendFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.resendFailed'),
            text: message,
            confirmButtonColor: '#3085d6',
        })
    },
    updateSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.updateSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    updateFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.updateFailed'),
            text: message,
            confirmButtonColor: '#3085d6',
        })
    },
    findFailed(vm, message = '') {
        if (message?.data?.response?.status !== 401) {
            vm.$swal.fire({
                icon: 'error',
                title: vm.$t('common.findFailed'),
                text: message,
                confirmButtonColor: '#3085d6',
            })
        } else {
            return true
        }
    },
    sendSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.sendSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    sendFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.sendFailed'),
            html: '<p>' + vm.$t('common.somethingWrong') + '</p>' + message,
            confirmButtonColor: '#3085d6',
        })
    },
    checkSpamWordSuccess(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.CheckSpamWordSuccess'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    checkSpamWordFailed(vm, message) {
        vm.$swal.fire({
            icon: 'error',
            title: '',
            html: message,
            confirmButtonColor: '#3085d6',
        })
    },
    usePromotionCodeFailed(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.usePromotionFailed'),
            html: vm.$t('common.somethingWrong') + '<br>' + message,
            confirmButtonColor: '#3085d6',
        })
    },
    success(vm) {
        vm.$swal.fire({
            icon: 'success',
            title: vm.$t('common.success'),
            showConfirmButton: false,
            timer: 1500
        })
    },
    fail(vm, message = '') {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.fail'),
            html: '<p>' + vm.$t('common.somethingWrong') + '</p>' + message,
            confirmButtonColor: '#3085d6',
        })
    },
    creditIsInsufficient(vm, message, redirect) {
        vm.$swal.fire({
            icon: 'error',
            title: vm.$t('common.fail'),
            html: '<p>' + vm.$t('common.somethingWrong') + '</p>' + message,
            confirmButtonText: vm.$t('button.byPackage')
        }).then(result => {
            if (result.isConfirmed) {
                vm.$router.push(`${redirect}`).catch(() => {
                })
            }
        })
    },
}